<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="showDrawer"
      :key="'showDrawer' + Number(showDrawer)"
      class="ui-drawer-component"
    >
      <div
        class="ui-drawer-background"
        :class="{ 'is-active': showDrawer }"
        @click="closeDrawer()"
      ></div>
      <div
        class="drawer"
        :class="[position, unsetFillModeCssClass, animateClass, drawerSize]"
      >
        <header class="drawer-head has-padding">
          <p v-if="!hideTitle" class="drawer-title">
            {{ drawerTitle }}
          </p>
          <button
            @click="closeDrawer()"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="drawer-body has-padding">
          <slot name="content"></slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    drawerTitle: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    onClickClose: {
      type: Function,
      default: function () {},
      required: false,
    },
    position: {
      type: String,
      default: 'right',
    },
    showDrawer: {
      type: Boolean,
      default: false,
    },

    /**
     * Sizes are:
     * - normal (420)
     * - large (800)
     * - extra-large (1100)
     */
    drawerSize: {
      type: String,
      default: 'normal',
    },
  },

  data() {
    return {
      slideDrawer: this.showDrawer,
      animateTimer: null,
      unsetFillModeCssClass: 'unset-fill-mode',
    }
  },

  computed: {
    animateClass() {
      let cls = 'animated slide'
      cls = cls + (this.slideDrawer ? 'In' : 'Out')
      cls = cls + this.position.charAt(0).toUpperCase() + this.position.slice(1)
      return cls
    },
  },

  beforeDestroy() {
    /**
     * Destroy timer
     */
    if (this.animateTimer) {
      clearTimeout(this.animateTimer)
    }
  },

  methods: {
    closeDrawer() {
      this.slideDrawer = false
      this.unsetFillModeCssClass = ''
      this.animateTimer = setTimeout(this.onClickClose, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';

$widthNormal: 420px;
$widthLarge: 800px;
$widthExtraLarge: 1100px;

.ui-drawer-component {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  .ui-drawer-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: rgba($black, 0.86);
    transition: opacity 1s ease-in-out;
    &.is-active {
      opacity: 1;
    }
  }

  .drawer {
    display: flex;
    flex-direction: column;
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    left: auto;
    right: auto;
    background-color: white;
    height: 100vh;
    max-width: 420px;
    &.large {
      max-width: $widthLarge;
    }
    &.extra-large {
      max-width: $widthExtraLarge;
    }
    transition: 0.75s;
    animation-duration: 0.5s;
    @include box-shadow();

    // This is needed for showing elements outside the drawer
    &.unset-fill-mode {
      animation-fill-mode: unset;
    }

    &.left {
      left: 0;
      width: 100%;
    }

    &.right {
      right: 0;
      width: 100%;
    }

    &-minimal {
      width: auto;
    }

    .drawer-head {
      align-items: center;
      background-color: #eeeeee;
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      padding: 15px;
      position: relative;

      .drawer-title {
        flex-grow: 1;
        flex-shrink: 0;
        font-size: $size-4;
      }
    }

    .drawer-body {
      overflow: auto;
      height: 100%;
      max-height: 100%;
      // overflow-x: hidden;
    }
  }
}
</style>
